

















































































































































































// @ts-nocheck
import {Vue, Component, Prop} from 'vue-property-decorator'
import {FormWizard, TabContent} from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BButton,
  BTable, BFormCheckbox
} from 'bootstrap-vue'
import vSelect from "vue-select";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {MaintenanceAPI} from "@/apps/maintenance-api/api/types";


@Component({
  components: {
    FormWizard,
    TabContent,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
    vSelect,
    BTable,
    BFormCheckbox,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  }
})
export default class EmployeeUserCreate extends Vue {
  azureSearch: string = null;
  azureUserFound = true;
  bypassAzureCheck = false;

  user: EmployeeAPI.User.User = null;

  scopeGroups: MaintenanceAPI.ScopeGroup.Item[] = [];

  crmaccess: EmployeeAPI.CrmAccess.CrmAccess[] = [
    {
      code: "TT1",
      name: "Test Branch"
    }
  ];

  nextButtonText = "Next";
  hideButtons = false;

  async created() {
    const scopeGroups = await this.$api.maintenance.roles.GetList();
    this.$set(this, "scopeGroups", scopeGroups);
  }

  formSubmitted() {
    this.$toast({
      component: ToastificationContent,
      props: {
        title: 'Form Submitted',
        icon: 'EditIcon',
        variant: 'success',
      },
    })
  }

  async searchForAzureUser() {
    const userCheck = await this.$api.employee.user.GetList(1, 1, this.azureSearch);
    if (userCheck.items.length > 0) {
      return await this.$router.push({
        name: 'employee-user',
        params: {
          identifier: userCheck.items[0].id,
        }
      });
    }

    if(this.bypassAzureCheck)
    {
      const user: EmployeeAPI.User.User = {
        firstName: null,
        lastName: null,
        fullName: null,
        email: this.azureSearch.toString(),
        azureId: null,
        scopeGroups: []
      };
      this.$set(this, "user", user);
      return true;
    }

    const items = await this.$api.employee.azure.graphDirectorySearch(this.azureSearch);
    if (items.length == 0 || items.length > 1) {
      this.azureUserFound = false;
      return false;
    }

    this.azureUserFound = true;

    const user: EmployeeAPI.User.User = {
      firstName: items[0].firstName,
      lastName: items[0].lastName,
      fullName: items[0].displayName,
      email: items[0].mail,
      azureId: items[0].azureId,
      scopeGroups: []
    };
    this.$set(this, "user", user);

    return true;
  }

  async userInfoSubmit() {
    this.nextButtonText = "Create User";
    return true;
  }

  async lastStep() {
    const newUserObj: EmployeeAPI.User.UserCreateRequest = {
      azureId: this.user.azureId,
      firstName: this.user.firstName,
      lastName: this.user.lastName,
      email: this.user.email,
      scopeGroups: this.user.scopeGroups.map(x => x.id)
    }

    const resp = await this.$api.employee.user.Create(newUserObj);
    if (resp != null) {
      this.$set(this, "user", resp);
      await this.getUsersCrmAccess();
      this.hideButtons = true;
      return true;
    } else {
      this.hideButtons = false;
      return false;
    }
  }

  async getUsersCrmAccess() {
    const crmaccess = await this.$api.employee.crmaccess.GetList(this.user.id);
    this.$set(this, "crmaccess", crmaccess);
  }

  async goToEmployee() {
    await this.$router.push({
      name: "employee-user",
      params: {
        identifier: this.user.id
      }
    })
  }
}
